<template>
  <quill-editor
    ref="myTextEditor"
    v-if="type === ''"
    v-model="pages.content"
    :config="editorOption"
  >
  </quill-editor>

  <quill-editor
    ref="myTextEditor"
    v-else
    v-model="pages.footer"
    :config="editorOption"
  >
  </quill-editor>
</template>

<style>
.ql-snow .ql-tooltip {
  z-index: 10000 !important;
}
</style>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: { quillEditor },
  props: {
    pages: {
      type: Object
    },
    type: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    editorOption: {}
  })
};
</script>
